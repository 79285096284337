(function($) {

	var map = null;
	var fontSizesStep = 0;

	// SET IMG
	$.fn.setImg = function() {
		var bg = $(this).data('img');
		$(this).css({
			'background-image': 'url(' + bg + ')'
		});
	};

	// MAKE SQUARE
	$.fn.makeSquare = function() {
		$(this).css({
			'height': parseInt($(this).width(), 10),
			'min-height': parseInt($(this).width(), 10)
		});
	};

	// MAKE RECTANGLE
	$.fn.makeRectangle = function() {
		var ratio = $(this).data('ratio');
		$(this).css({
			'height': parseInt($(this).width() * ratio, 10),
			'min-height': parseInt($(this).width() * ratio, 10)
		});
	};

	function new_map( $el ) {
		
		var $markers = $el.find('.marker');
		var args = {
			zoom		: 16,
			center		: new google.maps.LatLng(0, 0),
			mapTypeId	: google.maps.MapTypeId.ROADMAP,
			scrollwheel: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
			styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
		};
		var map = new google.maps.Map( $el[0], args);
		map.markers = [];
		$markers.each(function(){
	    	add_marker( $(this), map );
		});
		center_map( map );
		return map;
		
	}

	function add_marker( $marker, map ) {

		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
		var image = new google.maps.MarkerImage(
            'img/' + $marker.attr('data-icon') + '.png',
            null,
            null,
            null,
            new google.maps.Size(60, 65)
        );
		var marker = new google.maps.Marker({
			position	: latlng,
			map			: map,
			icon: image
		});
		map.markers.push( marker );
		if( $marker.html() )
		{
			var infowindow = new google.maps.InfoWindow({
				content		: $marker.html()
			});
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open( map, marker );
			});
		}

	}

	function center_map( map ) {

		var bounds = new google.maps.LatLngBounds();
		$.each( map.markers, function( i, marker ){
			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
			bounds.extend( latlng );
		});
		if( map.markers.length == 1 )
		{
		    map.setCenter( bounds.getCenter() );
		    map.setZoom( 16 );
		}
		else
		{
			map.fitBounds( bounds );
		}

	}

	function init() {

		$('.rectangle').each(function(index, elem) {
			$(elem).makeRectangle();
		});

		$('.square').each(function(index, elem) {
			$(elem).makeSquare();
		});

		$('.slave-height').each(function(index, elem) {
			$(elem).height($(elem).parent().find('.master-height').height());
		});

		$('.page-menu').each(function(index, elem) {
			var children = $(elem).children().length;
			$(elem).children().css('width', (100 / children) + '%');
			if($(elem).find('.active').length) {
				var offset = parseInt($(elem).find('.active').offset().left - $(elem).find('.active').outerWidth(true) / 2);
				$(elem).parents('.page-menu-container').scrollLeft(offset);
			}
		});

		if($(window).width() <= 767) {
			$('#pageMenuAffixContainer').height($('#pageMenuAffixContainer').children('#pageMenuAffix').outerHeight(true));
		}

		$(window).off('.affix');
		$('#pageMenuAffix').removeData('bs.affix').removeClass('affix affix-top affix-bottom');
		$('#pageMenuAffix').affix({
			offset: {
				top: $('.page-top').outerHeight(true) - $('#header').outerHeight(true)
			}
		})

		$('.masonry').imagesLoaded(function() {
			$('.masonry').masonry();
		});

	}

	function toggleSearch() {
		$('#searchToggle').find('i').toggleClass('icon-search').toggleClass('icon-close');
		$('#searchContainer').toggleClass('open');
		if($('#menuContainer').hasClass('open')) {
			$('#menuToggle').find('i').toggleClass('icon-menu').toggleClass('icon-close');
			$('#menuContainer').toggleClass('open');
		}
		else {
			$('#headerOverlay').toggleClass('open');
			$('#header .header-bottom').toggleClass('open');
			$('#header .header-bottom .btn').toggleClass('border-white');
			$('body').toggleClass('menu-open');
		}
	}

	function toggleMenu() {
		$('#menuToggle').find('i').toggleClass('icon-menu').toggleClass('icon-close');
		$('#menuContainer').toggleClass('open');
		if($('#searchContainer').hasClass('open')) {
			$('#searchToggle').find('i').toggleClass('icon-search').toggleClass('icon-close');
			$('#searchContainer').toggleClass('open');
		}
		else {
			$('#headerOverlay').toggleClass('open');
			$('#header .header-bottom').toggleClass('open');
			$('#header .header-bottom .btn').toggleClass('border-white');
			$('body').toggleClass('menu-open');
		}
	}

	function smoothScroll(target) {
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - $('.header').outerHeight(true) - $('#pageMenuAffix').outerHeight(true)
            }, 300);
            return false;
        }
    }

    function fixHeight() {
    	if($(window).width() > 767) {
	    	$('.row-height').each(function() {
				var maxHeight = -1;
				$(this).children('.col-height').each(function() {
					$(this).height('auto');
					maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
				});
				$(this).children('.col-height').each(function() {
					$(this).height(maxHeight);
				});
			});
	    }
    }

    $('.fontsize').on('click', function(e) {
    	e.preventDefault();
    	var fontSize = parseInt($('body').css('font-size').replace('px', ''));
    	var newFontSize;
    	fontSizesStep++;
    	if(fontSizesStep == 3) {
    		fontSizesStep = 0;
    		newFontSize = parseInt(fontSize - 2);
    	}
    	else {
    		newFontSize = parseInt(fontSize + 1);
    	}
    	$('body').css('font-size', newFontSize + 'px', 'important');
    	$('.masonry').masonry();
    	fixHeight();
	});

	$(document).ready(function() {

		$('[data-toggle="tooltip"]').tooltip({
			'trigger': 'hover'
		});

		$('.masonry').masonry({
			itemSelector: '.grid-item',
			columnWidth: '.grid-sizer',
			percentPosition: true
		});

		$('.map').each(function(){
			map = new_map( $(this) );
			google.maps.event.addDomListener(window, "resize", function() {
               center_map(map);
            });
		});

		$('#modalLogin').on('show.bs.modal hidden.bs.modal', function() {
			$('body').toggleClass('modal-login-open');
			$(this).find('.modal-content').height($(window).height() - 60);
		});

		$('.modal-prospetto-informativo').on('show.bs.modal hidden.bs.modal', function() {
			$('body').toggleClass('modal-prospetto-informativo-open');
		});

		$('#loginForm').validate({
			rules: {
				giornoDiNascita: {
					required: true,
					number: true,
					min: 1,
					max: 31
				},
				meseDiNascita: {
					required: true,
					number: true,
					min: 1,
					max: 12
				},
				annoDiNascita: {
					required: true,
					number: true
				}
			},
			groups: {
				dataDiNascita: "giornoDiNascita meseDiNascita annoDiNascita"
			},
			errorPlacement: function(error, element) {
				if (element.attr("name") == "giornoDiNascita" || element.attr("name") == "meseDiNascita" || element.attr("name") == "annoDiNascita") {
					error.insertAfter("#annoDiNascita");
				}
				else {
					error.insertAfter(element);
				}
			}
		});
		$('#registerForm').validate({
			rules: {
				cellulare: {
					required: true,
					number: true
				}
			}
		});

		$('.imgToDiv').each(function(index, elem) {
			$(elem).setImg();
		});

		$('.carousel').slick({
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 1000,
			fade: true,
			pauseOnHover: false,
			pauseOnFocus: false,
			swipe: false,
			prevArrow: '<i class="icon-arrow-light slick-prev hidden-xs hidden-sm"></i>',
			nextArrow: '<i class="icon-arrow-light slick-next hidden-xs hidden-sm"></i>'
		});

		$('#searchToggle').on('click', function(e) {
			e.preventDefault();
			toggleSearch();
		});

		$('#menuToggle').on('click', function(e) {
			e.preventDefault();
			toggleMenu();
		});

		$('.management-toggle').on('click', function(e) {
			e.preventDefault();
			$('.management-single').removeClass('open');
			$('#management' + $(this).data('management')).addClass('open');
		});

		$('.management-close').on('click', function(e) {
			e.preventDefault();
			$('.management-single').removeClass('open');
		});

		$('#yearsToggle').on('click', function() {
			$(this).toggleClass('upside-down');
			$('#yearsList').slideToggle('fast');
		});

		$('.collapse').on('show.bs.collapse', function() {
			$(this).parent().find('.icon-more').removeClass('icon-more').addClass('icon-minus');
		});

		$('.collapse').on('hide.bs.collapse', function() {
			$(this).parent().find('.icon-minus').removeClass('icon-minus').addClass('icon-more');
		});

		$('a[href*="#"]:not([href="#"])').on('click', function(e) {
			if(location.pathname == this.pathname && !$(this).hasClass('accordion-link') && !$(this).hasClass('tab-link')) {
				e.preventDefault();
	            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
	              var target = $(this.hash);
	              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
	              smoothScroll(target);
	            }
            }
        });

		if(window.location.hash) {
            smoothScroll($(window.location.hash));
        }

		init();

	});

	$(window).on('load', function() {
		//init();
		fixHeight();
	})

	$(window).resize(function() {
		init();
		fixHeight();
	})

	/*
	* Translated default messages for the jQuery validation plugin.
	* Locale: IT (Italian; Italiano)
	*/
	$.extend( $.validator.messages, {
		required: "Campo obbligatorio",
		remote: "Controlla questo campo",
		email: "Inserisci un indirizzo email valido",
		url: "Inserisci un indirizzo web valido",
		date: "Inserisci una data valida",
		dateISO: "Inserisci una data valida (ISO)",
		number: "Inserisci un numero valido",
		digits: "Inserisci solo numeri",
		creditcard: "Inserisci un numero di carta di credito valido",
		equalTo: "Il valore non corrisponde",
		extension: "Inserisci un valore con un&apos;estensione valida",
		maxlength: $.validator.format( "Non inserire pi&ugrave; di {0} caratteri" ),
		minlength: $.validator.format( "Inserisci almeno {0} caratteri" ),
		rangelength: $.validator.format( "Inserisci un valore compreso tra {0} e {1} caratteri" ),
		range: $.validator.format( "Inserisci un valore compreso tra {0} e {1}" ),
		max: $.validator.format( "Inserisci un valore minore o uguale a {0}" ),
		min: $.validator.format( "Inserisci un valore maggiore o uguale a {0}" ),
		nifES: "Inserisci un NIF valido",
		nieES: "Inserisci un NIE valido",
		cifES: "Inserisci un CIF valido",
		currency: "Inserisci una valuta valida"
	} );

})(jQuery);